// extracted by mini-css-extract-plugin
export var mvpAppDevBenefitsSection = "w_dh";
export var mvpAppDevCaseSection = "w_dr";
export var mvpAppDevChoiceSection = "w_dn";
export var mvpAppDevClientQuotes = "w_ds";
export var mvpAppDevCostSection = "w_dl";
export var mvpAppDevExpertiseSection = "w_dq";
export var mvpAppDevIndustriesSection = "w_df";
export var mvpAppDevPracticesSection = "w_dm";
export var mvpAppDevPrimeSection = "w_dc";
export var mvpAppDevProcessSection = "w_dj";
export var mvpAppDevProjLogoSection = "w_dp";
export var mvpAppDevServicesSection = "w_dd";
export var mvpAppDevTechSection = "w_dk";
export var mvpAppDevTypesSection = "w_dg";